import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Lead Platform - Acceptance',
  production: false,
  hmr: false,
  name: 'Acceptance',
  baseUrl: 'https://lg-electronics-acceptance.staging.emendis.nl/api',
  sentryUrl: 'https://b71a143b898f47bfbc9347b944bbbda2@o4504360297496576.ingest.sentry.io/4504377890308096',
  databaseName: 'lg.acceptance',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://lg-electronics.acceptatie.emendis.nl',
};
